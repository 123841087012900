<template>
  <AtomsTextHeader :type="2" class="header--with-line">{{ $t('Trend') }} {{ new Date().getFullYear() }}</AtomsTextHeader>
  <OrganismsListsWareList :items="featuredItems" type="slider" :withSliderPagination="true" list-name="Doporučené produkty"></OrganismsListsWareList>
</template>
<script setup>

const appConfig = useAppConfig();
const locale = useLocale();

const featuredItems = (await useApiFetch(`/api/wareList?idGroup=${appConfig.homePageGroupId}&sort=newest&availability=in-stock&withoutParameters=true&take=6&currencyId=${locale.getLocale().currencyId}&languageId=${locale.getLocale().languageId}`)).data.value.wareList.wares.items;

</script>